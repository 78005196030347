import {PAYPAL_PAYMENT_SUCCESS} from '../reducers/types/paymentTypes';
import axios from 'axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jwt from 'jsonwebtoken'
import { trackPromise } from 'react-promise-tracker'

const apiUrl = 'https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-banfook/public/api'
const auth = jwt.decode(localStorage.jwtToken)
const apiUrl_backup = 'https://pdmstriggereast.azurewebsites.net/online-pdms/public/api'


axios.interceptors.response.use(undefined, (err) => {
    const { config, message } = err;
    if (!config || !config.retry) {
      return Promise.reject(err);
    }
    // retry while Network timeout or Network Error
    if (!(message.includes("timeout") || message.includes("Network Error"))) {
  //    toast.error("err 2")
   //   return Promise.reject(err);
    }
    config.retry -= 1;
    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => {
        toast.error("Please wait")
        console.log("retry the request", config.url);
        resolve();
      }, config.retryDelay || 1000);
    });
    return delayRetryRequest.then(() => axios(config));
  });
  



export const paymentSuccess = (data ,order_id) => async dispatch =>{


    var mycount 
    var res

    var i;

     
    var test = 'y';
      
    try {
    for (i = 0; i < 5; i++) {
      //  toast.success('Server...')
        res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 3, retryDelay: 3000,  username,  test}))
        
        if(res.data.message === "testing : ") 
          {
              toast.error('Please wait')
              break;
          }    
        
        
    }
    } catch (error){}   

    try{

        var gateway_fee=sessionStorage.getItem('admin_price')  
        var subtotal_price=sessionStorage.getItem('subtotal_price')  
        var total_price=sessionStorage.getItem('total_price')  
        var username=sessionStorage.getItem('username')  

        mycount = 0
    

        for (i = 0; i < 5; i++) {
          //   toast.success('Connecting Server ...')
             res = await trackPromise(axios.post(`${apiUrl}/paypal-success-temp`, { retry: 5, retryDelay: 4000, data , auth , order_id, gateway_fee, subtotal_price, total_price, username}))
             console.log(res.data, 'suc')
             if(res.data.message == "Payment Successful") break;
             if (mycount > 3) 
                 if(res.data.message != "Payment Successful") break;
             mycount = mycount + 1 
             
         }
 
        for (i = 0; i < 5; i++) {
         //   toast.success('Connecting Server ...')
            res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 5, retryDelay: 4000, data , auth , order_id, gateway_fee, subtotal_price, total_price, username}))
            console.log(res.data, 'suc')
            if(res.data.message == "Payment Successful") break;
            if (mycount > 3) 
                if(res.data.message != "Payment Successful") break;
            mycount = mycount + 1 
            
        }

        if(res.data.actual_count === res.data.send_count )
        {
            toast.success('match')
          
            dispatch({
                type: PAYPAL_PAYMENT_SUCCESS,
                payload: res.data
             });
             return;
               
        }
        else
        {
                for (i = 0; i < 5; i++) {
                    toast.success('Update ...')
                    res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 5, retryDelay: 4000, data , auth , order_id, gateway_fee, subtotal_price, total_price, username}))
                    console.log(res.data, 'suc')
                    if(res.data.message == "Payment Successful") break;
                    if (mycount > 3) 
                        if(res.data.message != "Payment Successful") break;
                    mycount = mycount + 1 
                    
                }
         
                
    
        }
        
        
       
        } catch (error){

            
     
    
    
        }




        try{

            gateway_fee=sessionStorage.getItem('admin_price')  
            subtotal_price=sessionStorage.getItem('subtotal_price')  
            total_price=sessionStorage.getItem('total_price')  
            username=sessionStorage.getItem('username')  
    
            mycount = 0
        
            
            for (i = 0; i < 5; i++) {
             //   toast.success('Connecting Server ...')
                res = await trackPromise(axios.post(`${apiUrl}/paypal-success-temp`, { retry: 5, retryDelay: 4000,data , auth , order_id, gateway_fee, subtotal_price, total_price, username}))
                console.log(res.data, 'suc')
                if(res.data.message == "Payment Successful") break;
                if (mycount > 3) 
                    if(res.data.message != "Payment Successful") break;
                mycount = mycount + 1 
                
            }
    
            if(res.data.actual_count === res.data.send_count )
            {
                toast.success('match')
                dispatch({
                    type: PAYPAL_PAYMENT_SUCCESS,
                    payload: res.data
                 });

               return;

         
            }
            else
            {
               
                  
             
                    
        
            }
           
            
           
            } catch (error){
    
                
    
                  //  toast.error("Internal Server Error");
         
        
        
            }
    



            
        try{

          gateway_fee=sessionStorage.getItem('admin_price')  
          subtotal_price=sessionStorage.getItem('subtotal_price')  
          total_price=sessionStorage.getItem('total_price')  
          username=sessionStorage.getItem('username')  
  
          mycount = 0
      
          
          for (i = 0; i < 5; i++) {
           //   toast.success('Connecting Server ...')
              res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 5, retryDelay: 4000,data , auth , order_id, gateway_fee, subtotal_price, total_price, username}))
              console.log(res.data, 'suc')
              if(res.data.message == "Payment Successful") break;
              if (mycount > 3) 
                  if(res.data.message != "Payment Successful") break;
              mycount = mycount + 1 
              
          }
  
          if(res.data.actual_count === res.data.send_count )
          {
              toast.success('match')
              dispatch({
                  type: PAYPAL_PAYMENT_SUCCESS,
                  payload: res.data
               });

             return;

       
          }
          else
          {
             
                
           
                  
      
          }
         
          
         
          } catch (error){
  
              
  
       //           toast.error("Internal Server Error");
       
                  for (i = 0; i < 5; i++) {
                    //   toast.success('Connecting Server ...')
                       res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 5, retryDelay: 4000, data , auth , order_id, gateway_fee, subtotal_price, total_price, username}))
                       console.log(res.data, 'suc')
                       if(res.data.message == "Payment Successful") break;
                       if (mycount > 3) 
                           if(res.data.message != "Payment Successful") break;
                       mycount = mycount + 1 
                       
                   }
                   if(res.data.actual_count === res.data.send_count )
                   {
                       toast.success('match')
                       dispatch({
                           type: PAYPAL_PAYMENT_SUCCESS,
                           payload: res.data
                        });
         
                      return;
         
                
                   }
          }
  




}