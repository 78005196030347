import React  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paynowbutton from '../payments/paynowbutton'
import Paynowgatewaybutton from '../payments/paynowgatewaybutton'
import Container from "@material-ui/core/Container";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { useDispatch } from 'react-redux'
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useEffect } from 'react';
import {paymentSuccess} from '../../actions/paymentActions';
import {paycallback} from '../../actions/authActions';
import {logout} from '../../actions/authActions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import styles from './OApp.module.css';
import { useHistory } from "react-router-dom";
import {  toast } from 'react-toastify';
import QRCode from 'qrcode.react';
import axios from 'axios';

import 'react-toastify/dist/ReactToastify.css';
import jwt from 'jsonwebtoken'
import { trackPromise } from 'react-promise-tracker'


var encryptedStr = "";

axios.interceptors.response.use(undefined, (err) => {
  const { config, message } = err;
  if (!config || !config.retry) {
    return Promise.reject(err);
  }
  // retry while Network timeout or Network Error
  if (!(message.includes("timeout") || message.includes("Network Error"))) {
//    toast.error("err 2")
 //   return Promise.reject(err);
  }
  config.retry -= 1;
  const delayRetryRequest = new Promise((resolve) => {
    setTimeout(() => {
      toast.error("Please wait")
      console.log("retry the request", config.url);
      resolve();
    }, config.retryDelay || 1000);
  });
  return delayRetryRequest.then(() => axios(config));
});


function wait(ms){
  var start = new Date().getTime();
  var end = start;
  while(end < start + ms) {
    end = new Date().getTime();
 }
}


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow:'hidden',
    minWidth: '420px',
    
    width: '15%'
  },
  table: {
    minWidth: 800,
  },
  box: {
    height: 100,
    display: "flex",
    border: "1px solid black",
    padding: 8
  },
  spreadBox: {
    justifyContent: 'space-between',
    alignItems: "center"
  }
}));





  



function SimpleDialog(props) {

  const dispatch = useDispatch()
  const [dopenmy, setDopenmy] = React.useState(false);
  const [dopen_refmy, setDopen_refmy]= React.useState(true);
  const history = useHistory();
   
  var QRstring
  const paymentHandler = (details, data) => {
 
    /** Here you can call your backend API endpoint and update the database    */
    localStorage.setItem('paydone', JSON.stringify(props.data));
    encryptedStr = localStorage.getItem('data.orderIDInLocalStorage')
    
    QRstring = sessionStorage.getItem('mydataqr')

    sessionStorage.setItem('finalmsg',"YES")
    dispatch(paymentSuccess(props.data, "Paynow :"+encryptedStr ))
    //  dispatch(paycallback());
   //<Redirect to='/paynowcallback' />
   //const navigateTo = () => history.push('/paynowcallback');//eg.history.push('/login');
   
//   const history = createHistory();
//   history.push('http://localhost:3000/paynowcallback');
//   history.go(0);

// fail to go to the page ?
    
   confirmAlert({
    customUI: ({ onClose }) => {
    return (

      
    <div>
      <h1>Expire in 10 min.</h1>
      
      <div><p></p></div>  
      <Box display="flex" flexDirection="row-reverse" p={1} m={1} bgcolor="background.paper" alignItems="center" justifyContent="center" >
      <QRCode value={QRstring} size={140}/>
      </Box>
      <div></div>
      <div style={{textAlign: 'left' , fontWeight:"bold" }}> <p></p></div>
      <div style={{textAlign: 'left' , fontWeight:"bold" }}>Scan the QR code directly with Bank Mobile App.   </div>

<div style={{textAlign: 'left' , fontWeight:"bold" }}> <p></p></div>


<div style={{textAlign: 'left' , fontWeight:"bold" }}> <p>Or save this QR code to your photos,</p></div>
<div style={{textAlign: 'left' , fontWeight:"bold" }}> <p>then scan from Album.</p></div>

      <p></p>
      <button class={styles.button} 
      onClick={() => {
  //      onClose();
 //       toast.success("Logout");
 
        dispatch(logout());
 //       onClose();
      }}
      >
        Click here to Logout</button>
    </div>
    );
    }, closeOnEscape: true,  //**only press on ESC on keyboard to close**
    closeOnClickOutside: false  //**prevent click over white space to close the alert messag**e

    });   

   let propsdata = localStorage.getItem('propsdataInLocalStorage')
   let selected = localStorage.getItem('selectedInLocalStorage')
   var temp = localStorage.getItem('example');
   var viewName = JSON.parse(temp); 
   encryptedStr = localStorage.getItem('data.orderIDInLocalStorage')
    
  
      
         var temp = sessionStorage.getItem('mydata');
         var viewName = JSON.parse(temp); 
         var orderid = sessionStorage.getItem('data.orderIDInLocalStorage')  
         viewName.orderID = sessionStorage.getItem('data.orderIDInLocalStorage')  
     
         wait(100);  //7 seconds in milliseconds  
         
   
         
    
   
   }
   

 
  const { onClose, selectedValue, open } = props;
  const handleClose = () => {

    localStorage.setItem('paydone', JSON.stringify(props.data));
    
     const removeLocalStorage = localStorage.removeItem("jwtToken");
 //    dispatch(logout());
     toast.success("Message: Payment Cancel");
     //wait(3000)

     confirmAlert({
      customUI: ({ onClose }) => {
      return (
      <div>
        <h1>Message</h1>
        <p>Renewal will be cancel.</p>
        <button class={styles.button} 
        onClick={() => {
         // onClose();
  //        toast.success("Message : Logout");
   
          dispatch(logout());
   //       onClose();
        }}
        >
          Click here to Logout</button>
      </div>
      );
      }
      , closeOnEscape: true,  //**only press on ESC on keyboard to close**
    closeOnClickOutside: false  //**prevent click over white space to close the alert messag**e

      });   
  
     
     onClose(selectedValue);
 
   };



  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1.5),
    },
  }))(MuiDialogActions);


  var total_price = 0
  for (let i = 0; i < props.data.length ; i++) {

    let data = props.data[i];

    total_price = total_price + parseInt(data.interest_payable)

  }


  const date = new Date();
  const hours = date.getHours();
  const mins = date.getMinutes();

  if (hours === 23) {

    if (mins > 30) {
      return (
        <Dialog  aria-labelledby="simple-dialog-title" open={open}>
          <DialogTitle id="Payment"></DialogTitle>
       
          <Container component="main" maxWidth="xs">
    
      
         
     
            
          <DialogActions>
    
          <Box component="span"  className={styles.box,styles.spreadBox}   m={1}
    >
    
    
          
              <Button autoFocus onClick={handleClose} color="secondary" fullWidth variant="contained" size="small" style={{ borderRadius: 20 , padding: '60px 20px 60px 20px' , textAlign: 'center',height: 80 ,width: 150 , margin: '0px 0px 15px 8px'}}>
              The website is under maintenance from 11.45pm to 12.15am.
              </Button>
      
              </Box>
    
            </DialogActions>
    
            </Container>
        </Dialog>
    
        
      );
    }
    
  }
  else return (
    <Dialog  aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="Payment"></DialogTitle>
   
      <Container component="main" maxWidth="xs">

      <Paynowbutton  data={props.data} total={total_price} orderID={total_price} />
  
     
 
        
      <DialogActions>

      <Box component="span"  className={styles.box,styles.spreadBox}   m={1}
>


      <Button autoFocus onClick={paymentHandler} color="primary" fullWidth variant="contained" size="small" style={{ borderRadius: 20, padding: '60px 20px 60px 20px', textAlign: 'center', height: 80 , width: 190 , margin: '0px 8px 15px 0px'}}>
            Click_here to_Accept. 
          </Button>
      
          <Button autoFocus onClick={handleClose} color="secondary" fullWidth variant="contained" size="small" style={{ borderRadius: 20 , padding: '60px 20px 60px 20px' , textAlign: 'center',height: 80 ,width: 150 , margin: '0px 0px 15px 8px'}}>
            Cancel
          </Button>
  
          </Box>

        </DialogActions>

        </Container>
    </Dialog>

    
  );
}


SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};




function SimpleDialog_M(props) {
  
  
  
  


  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };


  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1.5),
    },
  }))(MuiDialogActions);

  const style = {
    layout: "horizontal", // horizontal | vertical
    size: "responsive", // medium | large | responsive
    shape: "rect", // pill | rect
    color: "gold" // gold | blue | silver | white | black
  };

  var total_price = 0
  for (let i = 0; i < props.data.length ; i++) {

    let data = props.data[i];

    total_price = total_price + parseInt(data.interest_payable)

  }

  return (
    <Dialog  aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="Payment"></DialogTitle>
   
      <Container component="main" maxWidth="xs">
   
     
      <Paynowgatewaybutton  data={props.data} total={total_price} orderID={total_price} />
  
     
 
   
      </Container>

      <DialogActions>
    
          <Button autoFocus onClick={handleClose} color="secondary" variant="contained" size="small">
            Cancel
          </Button>
        </DialogActions>

    </Dialog>
  );
}


SimpleDialog_M.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};


export default function SelectListModal(props) {
  

  const classes = useStyles();
  const [dopen, setDopen] = React.useState(false);
  const [dopen_m, setDopen_m] = React.useState(false);
  const [dopen_ref, setDopen_ref]= React.useState(true);
  const [Loading, setLoading] = React.useState(false);

  const dhandleClickOpen = () => {
    if (total_price>0) {
      setDopen(true);
    } else {
    
    }
  };

  const dhandleClose = (value) => {
    setDopen(false);
  };

  const dhandleOPen = (value) => {
    
  };

  const dhandleClickOpen_m = () => {
    if (total_price>0) {
      setDopen_m(true);

      
  sessionStorage.setItem('admin_price', '0' );
sessionStorage.setItem('subtotal_price', total_price );
sessionStorage.setItem('total_price', total_price );
    } else {
    
    }
  };

  const dhandleClose_m = (value) => {
    setDopen_m(false);
  };

  var total_price = 0

  for (let i = 0; i < props.data.length ; i++) {
    let data = props.data[i];
    total_price = total_price + parseInt(data.interest_payable)
  }


  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {

    encryptedStr = localStorage.getItem('data.orderIDInLocalStorage')
    
    if (dopen_ref)
    {

 

    setDopen_ref(false);
    props.data.orderID = encryptedStr
    
    

    }

    
  });

  var shopname = sessionStorage.getItem('shop')  
  var isactive

  if (shopname === 'Ban Fook Pawnshop Woodlands'  )
  {
  isactive = true
  }
  if (shopname === 'Ban Fook Pawnshop Sembawang'  )
  {
    isactive = true
  }
  isactive = true
  
  return (
    <div>

    <Grid
      container 
    >
      <Grid item>

{/*
 
      <div id="paypal-button-container"></div>
      <PaymentPaypal data={props.data} total={total_price}  />       
 
*/}

      
{isactive ?  
      
      <Button type="submit" fullWidth variant="contained" color="secondary" className={classes.submit} onClick={dhandleClickOpen}>
      Paynow - Scan and Click Here
      </Button>
      
 : 
 <Button type="submit" fullWidth variant="contained" color="secondary" className={classes.submit} >
 QRcode Not Avail - Unable to Process Payment 
 </Button>

}

<SimpleDialog  open={dopen} onClose={dhandleClose}  data={props.data} />
     

    <div>
    <p> </p>
    </div>

 {/*
  
      <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} onClick={dhandleClickOpen_m}>
      Paynow Maesh Gateway
      </Button>
      <SimpleDialog_M  open={dopen_m} onClose={dhandleClose_m} data={props.data} />
  
   */}
  
      <div>
      <p> 
      </p>
      </div>

 
 
   
      <div>
<p> 
</p>
</div>

      </Grid>
    </Grid>
    </div>
  );
}
